<template>
  <div id="app">
    <div class="gjmb">
      <div class="banner">
        <h1>方案名称2019-3-25监测周报</h1>
        <p><span>报告于2019-3-25 17:40:37由舆情管理系统生成</span></p>
      </div>
      <div class="cont">
        <h2>一、概述</h2>
        <p>本次周报搜集了[2018-12-18 09:00至2018-12-25 09:00]在[全部媒体]上关于[刘强东+无罪]的[所有]数据。共[3000]条。</p>
        <h2>二、舆情态势</h2>
        <p>
          从舆情态势来看，每日平均含[286]条舆情信息，整体[有/无]特殊舆情事件发生，整体趋势为[非典型性波动、单热点、持续爆发、一般热度的长尾型传播]。总体声量在[2018-12-20]当日达到高峰，[2018-12-24]当日为低谷。</p>
        <el-card class="box-card xxsmzs mar-t-20 mod-echarts-box">
          <h3 class="mod-title">信息声量走势</h3>
          <div class="mod-echarts-cont" id="voicetrend">
          </div>
        </el-card>
        <table class="xxjd_table1">
          <tr>
            <th>序号</th>
            <th>总体信息高峰日焦点</th>
            <th class="w-163">发布时间</th>
            <th>代表来源</th>
            <th>相似数</th>
            <th>属性</th>
          </tr>
          <tr>
            <td>1</td>
            <td><a class="title">#华为CFO孟晚舟事件#@华为中国期待中...!平安归来！</a></td>
            <td class="w-163">2018-12-26 09:36:20</td>
            <td><a class="source">微博@丝路中国</a></td>
            <td>12</td>
            <td><span class="text-mg">敏感</span></td>
          </tr>
          <tr>
            <td>2</td>
            <td><a class="title">
              #孟晚舟获得保释##华为CFO孟晚舟被捕##无眼联盟封杀华为##中国最应该敬重的艺术家#「宠爱益生」http://cn/E4dhDFo
            </a></td>
            <td class="w-163">2018-12-26 09:36:20</td>
            <td><a class="source">微博@潇筱凝</a></td>
            <td>5</td>
            <td><span class="text-mg">敏感</span></td>
          </tr>
          <tr>
            <td>3</td>
            <td><a class="title">华为董事长梁华首次回应“孟晚舟被扣”</a></td>
            <td class="w-163">2018-12-26 09:36:20</td>
            <td><a class="source">微信／观察舟</a></td>
            <td>80</td>
            <td><span class="text-mg">敏感</span></td>
          </tr>
        </table>
        <p class="mar-t-20">
          整体包含[1000]条敏感信息，占了总体信息的[50%]，每日平均包含[143]条敏感信息，[但整体而言分布平均，没有哪一天的敏感信息远高于其他日/其中，[2018-12-20]当日的敏感信息远超过平均。</p>
          <el-row class="el-row-20 mar-t-20" :gutter="20">
            <el-col :span="12">
              <el-card class="box-card mod-echarts-box">
                <h3 class="mod-title">情感声量走势</h3>
                <div class="mod-echarts-cont" id="emotiontrend"></div>
              </el-card>
            </el-col>
            <el-col :span="12">
              <el-card class="box-card mod-echarts-box">
                <h3 class="mod-title"><span class="red">敏感 </span>信息占比</h3>
                <div class="mod-echarts-cont" id="sensitive"></div>
              </el-card>
            </el-col>
          </el-row>
        <table class="xxjd_table1">
          <tr>
            <th>序号</th>
            <th>敏感信息高峰日焦点</th>
            <th class="w-163">发布时间</th>
            <th>代表来源</th>
            <th>相似数</th>
            <th>属性</th>
          </tr>
          <tr>
            <td>1</td>
            <td><a class="title">#华为CFO孟晚舟事件#@华为中国期待中...!平安归来！</a></td>
            <td class="w-163">2018-12-26 09:36:20</td>
            <td><a class="source">微博@丝路中国</a></td>
            <td>12</td>
            <td><span class="text-mg">敏感</span></td>
          </tr>
          <tr>
            <td>2</td>
            <td><a class="title">
              #孟晚舟获得保释##华为CFO孟晚舟被捕##无眼联盟封杀华为##中国最应该敬重的艺术家#「宠爱益生」http://cn/E4dhDFo
            </a></td>
            <td class="w-163">2018-12-26 09:36:20</td>
            <td><a class="source">微博@潇筱凝</a></td>
            <td>5</td>
            <td><span class="text-fmg">非敏感</span></td>
          </tr>
          <tr>
            <td>3</td>
            <td><a class="title">华为董事长梁华首次回应“孟晚舟被扣”</a></td>
            <td class="w-163">2018-12-26 09:36:20</td>
            <td><a class="source">微信／观察舟</a></td>
            <td>80</td>
            <td><span class="text-mg">敏感</span></td>
          </tr>
        </table>
        <h2 class="mar-t-20">三、舆情焦点</h2>
        <p>本周[方案名称]包含[1200]条网媒数据、[1000]条微博数据、[600]条微信数据、[200]条其他数据，各媒体来源的舆情焦点呈现如下：</p>
        <h3 class="mar-t-20 h3">（一）网媒焦点</h3>
        <table class="xxjd_table1">
          <tr>
            <th>序号</th>
            <th>标题/摘要</th>
            <th class="w-163">发布时间</th>
            <th>代表来源</th>
            <th>相似数</th>
            <th>属性</th>
          </tr>
          <tr>
            <td>1</td>
            <td>
              <a class="title">#华为CFO孟晚舟事件#@华为中国期待中...!平安归来！</a>
              <p>华为公共及政府事务部在内部论坛心声社区发表了一篇来自华为CFO、任正非之女孟晚舟的近期日记，日记字里行间流淌着孟对过去一段时间外界给予她关切及温暖的感动，其中特别提到了一位素昧平生日本市民的祝福来信。当时距离...</p>
            </td>
            <td class="w-163">2018-12-26 09:36:20</td>
            <td><a class="source">微博@丝路中国</a></td>
            <td>12</td>
            <td><span class="text-mg">敏感</span></td>
          </tr>
          <tr>
            <td>2</td>
            <td>
              <a class="title">
                #孟晚舟获得保释##华为CFO孟晚舟被捕##无眼联盟封杀华为##中国最应该敬重的艺术家#「宠爱益生」http://cn/E4dhDFo
              </a>
              <p>华为公共及政府事务部在内部论坛心声社区发表了一篇来自华为CFO、任正非之女孟晚舟的近期日记，日记字里行间流淌着孟对过去一段时间外界给予她关切及温暖的感动，其中特别提到了一位素昧平生日本市民的祝福来信。 当时距离...</p>
            </td>
            <td class="w-163">2018-12-26 09:36:20</td>
            <td><a class="source">微博@潇筱凝</a></td>
            <td>5</td>
            <td><span class="text-mg">敏感</span></td>
          </tr>
          <tr>
            <td>3</td>
            <td>
              <a class="title">华为董事长梁华首次回应“孟晚舟被扣”</a>
              <p>华为公共及政府事务部在内部论坛心声社区发表了一篇来自华为CFO、任正非之女孟晚舟的近期日记，日记字里行间流淌着孟对过去一段时间外界给予她关切及温暖的感动，其中特别提到了一位素昧平生日本市民的祝福来信。当时距离...</p>
            </td>
            <td class="w-163">2018-12-26 09:36:20</td>
            <td><a class="source">微信／观察舟</a></td>
            <td>80</td>
            <td><span class="text-mg">敏感</span></td>
          </tr>
          <tr>
            <td>4</td>
            <td>
              <a class="title">华为董事长梁华首次公开谈孟晚舟事件：期待“晚舟早归航”已获26个5G合同</a>
              <p>华为公共及政府事务部在内部论坛心声社区发表了一篇来自华为CFO、任正非之女孟晚舟的近期日记，日记字里行间流淌着孟对过去一段时间外界给予她关切及温暖的感动，其中特别提到了一位素昧平生日本市民的祝福来信。当时距离...</p>
            </td>
            <td class="w-163">2018-12-26 09:36:20</td>
            <td><a class="source">网媒／观察舟</a></td>
            <td>16</td>
            <td><span class="text-fmg">非敏感</span></td>
          </tr>
          <tr>
            <td>5</td>
            <td>
              <a class="title">华为大约是在冬季</a>
              <p>华为公共及政府事务部在内部论坛心声社区发表了一篇来自华为CFO、任正非之女孟晚舟的近期日记，日记字里行间流淌着孟对过去一段时间外界给予她关切及温暖的感动，其中特别提到了一位素昧平生日本市民的祝福来信。当时距离...</p>
            </td>
            <td class="w-163">2018-12-26 09:36:20</td>
            <td><a class="source">网媒／虎嗅网</a></td>
            <td>8</td>
            <td><span class="text-mg">敏感</span></td>
          </tr>
        </table>
        <h3 class="mar-t-20 h3">（二）微博焦点</h3>
        <table class="xxjd_table1">
          <tr>
            <th>序号</th>
            <th>标题/摘要</th>
            <th class="w-163">发布时间</th>
            <th>代表来源</th>
            <th>相似数</th>
            <th>属性</th>
          </tr>
          <tr>
            <td>1</td>
            <td>
              <a class="title">#华为CFO孟晚舟事件#@华为中国期待中...!平安归来！</a>
              <p>华为公共及政府事务部在内部论坛心声社区发表了一篇来自华为CFO、任正非之女孟晚舟的近期日记，日记字里行间流淌着孟对过去一段时间外界给予她关切及温暖的感动，其中特别提到了一位素昧平生日本市民的祝福来信。当时距离...</p>
            </td>
            <td class="w-163">2018-12-26 09:36:20</td>
            <td><a class="source">微博@丝路中国</a></td>
            <td>12</td>
            <td><span class="text-mg">敏感</span></td>
          </tr>
          <tr>
            <td>2</td>
            <td>
              <a class="title">#孟晚舟获得保释##华为CFO孟晚舟被捕##无眼联盟封杀华为##中国最应该敬重的艺术家#「宠爱益生」http://cn/E4dhDFo
              </a>
              <p>华为公共及政府事务部在内部论坛心声社区发表了一篇来自华为CFO、任正非之女孟晚舟的近期日记，日记字里行间流淌着孟对过去一段时间外界给予她关切及温暖的感动，其中特别提到了一位素昧平生日本市民的祝福来信。当时距离...</p>
            </td>
            <td class="w-163">2018-12-26 09:36:20</td>
            <td><a class="source">微博@潇筱凝</a></td>
            <td>5</td>
            <td><span class="text-mg">敏感</span></td>
          </tr>
          <tr>
            <td>3</td>
            <td>
              <a class="title">华为董事长梁华首次回应“孟晚舟被扣”</a>
              <p>华为公共及政府事务部在内部论坛心声社区发表了一篇来自华为CFO、任正非之女孟晚舟的近期日记，日记字里行间流淌着孟对过去一段时间外界给予她关切及温暖的感动，其中特别提到了一位素昧平生日本市民的祝福来信。当时距离...</p>
            </td>
            <td class="w-163">2018-12-26 09:36:20</td>
            <td><a class="source">微信／观察舟</a></td>
            <td>80</td>
            <td><span class="text-mg">敏感</span></td>
          </tr>
          <tr>
            <td>4</td>
            <td>
              <a class="title">华为董事长梁华首次公开谈孟晚舟事件：期待“晚舟早归航”已获26个5G合同</a>
              <p>华为公共及政府事务部在内部论坛心声社区发表了一篇来自华为CFO、任正非之女孟晚舟的近期日记，日记字里行间流淌着孟对过去一段时间外界给予她关切及温暖的感动，其中特别提到了一位素昧平生日本市民的祝福来信。当时距离...</p>
            </td>
            <td class="w-163">2018-12-26 09:36:20</td>
            <td><a class="source">网媒／观察舟</a></td>
            <td>16</td>
            <td><span class="text-fmg">非敏感</span></td>
          </tr>
          <tr>
            <td>5</td>
            <td>
              <a class="title">华为大约是在冬季</a>
              <p>华为公共及政府事务部在内部论坛心声社区发表了一篇来自华为CFO、任正非之女孟晚舟的近期日记，日记字里行间流淌着孟对过去一段时间外界给予她关切及温暖的感动，其中特别提到了一位素昧平生日本市民的祝福来信。当时距离...</p>
            </td>
            <td class="w-163">2018-12-26 09:36:20</td>
            <td><a class="source">网媒／虎嗅网</a></td>
            <td>8</td>
            <td><span class="text-mg">敏感</span></td>
          </tr>
        </table>
        <h3 class="mar-t-20 h3">（三）微信焦点</h3>
        <table class="xxjd_table1">
          <tr>
            <th>序号</th>
            <th>标题/摘要</th>
            <th class="w-163">发布时间</th>
            <th>代表来源</th>
            <th>相似数</th>
            <th>属性</th>
          </tr>
          <tr>
            <td>1</td>
            <td>
              <a class="title">#华为CFO孟晚舟事件#@华为中国期待中...!平安归来！</a>
              <p>华为公共及政府事务部在内部论坛心声社区发表了一篇来自华为CFO、任正非之女孟晚舟的近期日记，日记字里行间流淌着孟对过去一段时间外界给予她关切及温暖的感动，其中特别提到了一位素昧平生日本市民的祝福来信。 当时距离...</p>
            </td>
            <td class="w-163">2018-12-26 09:36:20</td>
            <td><a class="source">微博@丝路中国</a></td>
            <td>12</td>
            <td><span class="text-mg">敏感</span></td>
          </tr>
          <tr>
            <td>2</td>
            <td>
              <a class="title">#孟晚舟获得保释##华为CFO孟晚舟被捕##无眼联盟封杀华为##中国最应该敬重的艺术家#「宠爱益生」http://cn/E4dhDFo
              </a>
              <p>华为公共及政府事务部在内部论坛心声社区发表了一篇来自华为CFO、任正非之女孟晚舟的近期日记，日记字里行间流淌着孟对过去一段时间外界给予她关切及温暖的感动，其中特别提到了一位素昧平生日本市民的祝福来信。当时距离...</p>
            </td>
            <td class="w-163">2018-12-26 09:36:20</td>
            <td><a class="source">微博@潇筱凝</a></td>
            <td>5</td>
            <td><span class="text-mg">敏感</span></td>
          </tr>
          <tr>
            <td>3</td>
            <td>
              <a class="title">华为董事长梁华首次回应“孟晚舟被扣”</a>
              <p>华为公共及政府事务部在内部论坛心声社区发表了一篇来自华为CFO、任正非之女孟晚舟的近期日记，日记字里行间流淌着孟对过去一段时间外界给予她关切及温暖的感动，其中特别提到了一位素昧平生日本市民的祝福来信。当时距离...</p>
            </td>
            <td class="w-163">2018-12-26 09:36:20</td>
            <td><a class="source">微信／观察舟</a></td>
            <td>80</td>
            <td><span class="text-mg">敏感</span></td>
          </tr>
          <tr>
            <td>4</td>
            <td>
              <a class="title">华为董事长梁华首次公开谈孟晚舟事件：期待“晚舟早归航”已获26个5G合同</a>
              <p>华为公共及政府事务部在内部论坛心声社区发表了一篇来自华为CFO、任正非之女孟晚舟的近期日记，日记字里行间流淌着孟对过去一段时间外界给予她关切及温暖的感动，其中特别提到了一位素昧平生日本市民的祝福来信。 当时距离...</p>
            </td>
            <td class="w-163">2018-12-26 09:36:20</td>
            <td><a class="source">网媒／观察舟</a></td>
            <td>16</td>
            <td><span class="text-fmg">非敏感</span></td>
          </tr>
          <tr>
            <td>5</td>
            <td>
              <a class="title">华为大约是在冬季</a>
              <p>华为公共及政府事务部在内部论坛心声社区发表了一篇来自华为CFO、任正非之女孟晚舟的近期日记，日记字里行间流淌着孟对过去一段时间外界给予她关切及温暖的感动，其中特别提到了一位素昧平生日本市民的祝福来信。当时距离...</p>
            </td>
            <td class="w-163">2018-12-26 09:36:20</td>
            <td><a class="source">网媒／虎嗅网</a></td>
            <td>8</td>
            <td><span class="text-mg">敏感</span></td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
  import {globalGetEcharts} from '@/utils/helpers';
  export default {
    name: 'autobrief-classic',
    data() {
      return {
        voicetrend: {
          "keys": ["2019-08-09 00:00:00", "2019-08-10 00:00:00", "2019-08-11 00:00:00", "2019-08-12 00:00:00", "2019-08-13 00:00:00", "2019-08-14 00:00:00", "2019-08-15 00:00:00", "2019-08-16 00:00:00"],
          "legend": ["网媒", "微博", "全部"],
          "data": [[0, 18, 22, 67, 86, 41, 27, 31], [0, 1, 1, 0, 1, 0, 1, 0], [0, 19, 23, 67, 87, 41, 28, 31]]
        },
        emotiontrend: {
          "key": ["2019-08-09 00:00:00", "2019-08-10 00:00:00", "2019-08-11 00:00:00", "2019-08-12 00:00:00", "2019-08-13 00:00:00", "2019-08-14 00:00:00", "2019-08-15 00:00:00", "2019-08-16 00:00:00"],
          "data": [[0, 19, 23, 67, 87, 41, 28, 31], [0, 0, 0, 0, 0, 0, 0, 0]],
          "legend": ["敏感", "非敏感"]
        },
        sensitive: {"neg_num": 280, "pos_num": 12}
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.voic();
        this.em();
        this.sen();
      })
    },
    methods: {
      voic() {
        var element = window.document.getElementById('voicetrend')
        var voicetrend = this.voicetrend;
        var data = voicetrend.data.map(function (item, index) {
          return {
            data: item, type: 'line', name: voicetrend.legend[index], smooth: true, areaStyle: {
              normal: {}
            }
          }
        })
        var option = {
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            top: "10px",
            data: voicetrend.legend,
            itemWidth:10,
            itemHeight:10,
            itemGap:20,
            icon: "circle"
          },
          dataZoom: [
            {
              type: 'inside',
              start: 0,
              end: 100
            }
          ],
          grid: {
            left: '20px',
            right: '40px',
            top: '60px',
            bottom: '0',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: voicetrend.keys
          },
          yAxis: {
            type: 'value'
          },
          color: ['#4fcfb0', '#238fff', '#ff4f65', '#f98d3b', '#febd2e', '#7bbdff', '#2ecd42', '#7a5f08', '#95bedd', '#8e8e8e'],
          series: data
        }
        if (!element) return false
        var echarts = globalGetEcharts();
        var myChart = echarts.init(element);
        myChart.setOption(option);
      },
      em() {
        var element = window.document.getElementById('emotiontrend')
        var emotiontrend = this.emotiontrend;
        var echarts = globalGetEcharts();
        emotiontrend.legend.map(function (item, index) {
          if (item === '敏感') {
            emotiontrend.negative = emotiontrend.data[index]
          } else if (item === '非敏感') {
            emotiontrend.positive = emotiontrend.data[index]
          }
        })
        var option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          legend: {
            data: ['敏感', '非敏感'],
            top: "10px",
            itemWidth:10,
            itemHeight:10,
            itemGap:20,
            icon: "circle"
          },
          grid: {
            left: '20px',
            right: '40px',
            top: '60px',
            bottom: '0',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data: emotiontrend.key
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          dataZoom: [
            {
              type: 'inside',
              start: 0,
              end: 100
            }
          ],
          color: ['#FC5D73','#555DFE'],
          series: [
            {
              name: '敏感',
              type: 'line',
//             stack: '总量',
              data: emotiontrend.negative,
              smooth: true,
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#ff4f65'
                  }, {
                    offset: 1,
                    color: '#fff'
                  }])
                }
              },
            },
            {
              name: '非敏感',
              type: 'line',
//             stack: '总量',
              data: emotiontrend.positive,
              smooth: true,
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#238fff'
                  }, {
                    offset: 1,
                    color: '#fff'
                  }])
                }
              },
            }
          ]
        }
        if (!element) return false
        var myChart = echarts.init(element);
        myChart.setOption(option);
      },
      sen() {
        var element = window.document.getElementById('sensitive')
        var sensitive = this.sensitive;
        var total = sensitive.neg_num + sensitive.pos_num
        var option = {
          tooltip: {
            trigger: 'item',
            formatter: "{a} <br/>{b}: ({d}%)"
          },
          legend: {
            // orient: 'vertical',
            // x: 'left',
            data: ['敏感', '非敏感'],
            top: "10px",
            itemWidth:10,
            itemHeight:10,
            itemGap:20,
            icon: "circle",
            formatter: function (name) {
              return name == "敏感" ? "敏感 " + (sensitive.neg_num / total * 100).toFixed(0) + "%" : "非敏感 " + (sensitive.pos_num / total * 100).toFixed(0) + "%"
            }
          },
          color: ['#FC5D73','#555DFE'],
          series: [
            {
              name: '情感分布',
              type: 'pie',
              center: ['50%', '55%'],
              radius: ['45%', '60%'],
              avoidLabelOverlap: false,
              label: {
                normal: {
                  show: false,
                  position: 'center'
                },
                emphasis: {
                  show: true,
                  textStyle: {
                    fontSize: '30',
                    fontWeight: 'bold'
                  }
                }
              },
              labelLine: {
                normal: {
                  show: false
                }
              },
              data: [{name: "敏感", value: sensitive.neg_num}, {name: "非敏感", value: sensitive.pos_num}]
            }
          ]
        }
        if (!element) return false
        var echarts = globalGetEcharts();
        var myChart = echarts.init(element);
        myChart.setOption(option);
      }
    }
  }
</script>
<style scoped>
table {border-collapse: collapse;}
.h-420{height: 420px;}
.h-420 .mod-echarts-cont{height:380px;}
.mod-title{margin-left: 0;}
.gjmb {margin: 0 auto;background: #fff;padding-bottom: 30px;width: 1380px;box-shadow: 15px 0 15px -15px rgba(194, 194, 194, 0.4), -15px 0 15px -15px rgba(194, 194, 194, 0.4);}
.gjmb .cont{padding:20px 50px 0;}
.gjmb h2{font-size: 24px;line-height:60px;margin-top: 20px;}
.gjmb p,.gjmb .h3{font-size: 20px;line-height: 40px;color:rgba(0,0,0,.8);}
.xxsmzs{width:66%;margin:30px auto;}
.box-card{box-shadow: 0px 3px 15px 0px rgba(194, 194, 194, 0.4);border-radius: 6px;padding: 20px;}
.xxjd_table1 {width: 100%;margin-top: 20px;}
.xxjd_table1, .xxjd_table1 tr, .xxjd_table1 th, .xxjd_table1 td {border: 2px solid #A4A4A4;line-height: 50px;}
.xxjd_table1 th {font-size: 18px;}
.xxjd_table1 td {font-size: 14px;text-align: center;}
.xxjd_table1 td, .xxjd_table1 th {padding: 0 10px;}
.xxjd_table1 .title, .xxjd_table1 .source {overflow: hidden;white-space: nowrap;text-overflow: ellipsis;display: block;}
.xxjd_table1 .title, .xxjd_table1 p {text-align: left;width: 520px;font-size: 14px;line-height: 24px;}
.xxjd_table1 .title {font-weight:600;margin-top: 10px;font-size: 16px;}
.xxjd_table1 .source {width: 118px;}
.xxjd_table1 p {margin-bottom: 10px;overflow: hidden;}
.xxjd_table1 .text-mg{color: #f00;}
.xxjd_table1 .text-fmg {color:#555DFE;}
</style>
